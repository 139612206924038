import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "../reducers/layer";
import axios from "axios"; // call은 동기, fork는 비동기 요청
function* getAllLayers(action) {
    const { id } = action; // global.ts 에서 vrType 을 받아옴
    // const { vrtype } = useSelector((state) => state.global);
    const vrtype = yield select(state => state.global.vrtype); // vrtype에 따라 다른 url을 호출
    let url = "";
    if (vrtype === "LINC") {
        url = "https://asia-northeast3-onna-builder-linc.cloudfunctions.net/getLayerData";
    }
    else if (vrtype === "HIVE") {
        url = "https://asia-northeast3-onna-hive.cloudfunctions.net/getLayerData";
    }
    else {
        url = "https://asia-northeast3-onna-b7783.cloudfunctions.net/getLayerData";
    }
    try {
        const res = yield axios.post(url, // "http://127.0.0.1:5001/onna-b7783/asia-northeast3/getLayerData",
        { id });
        const data = res.data.data;
        yield put({ type: actions.GET_ALL_LAYERS_SUCCESS, data });
    }
    catch (err) {
        yield put({ type: actions.GET_ALL_LAYERS_FAILURE, error: err.message });
    }
}
function* getDefaultLayerAction(action) {
    const { id } = action; // global.ts 에서 vrType 을 받아옴
    const vrtype = yield select(state => state.global.vrtype); // vrtype에 따라 다른 url을 호출
    let url = "";
    if (vrtype === "LINC") {
        url = "https://asia-northeast3-onna-builder-linc.cloudfunctions.net/getDefaultLayersData";
    }
    else if (vrtype === "HIVE") {
        url = "https://asia-northeast3-onna-hive.cloudfunctions.net/getDefaultLayersData";
    }
    else {
        url = "https://asia-northeast3-onna-b7783.cloudfunctions.net/getDefaultLayersData";
    }
    try {
        const res = yield axios.post(url, { id });
        const data = res.data.data;
        yield put({ type: actions.GET_DEFAULT_LAYERS_SUCCESS, data });
    }
    catch (err) {
        yield put({ type: actions.GET_DEFAULT_LAYERS_FAILURE, error: err.message });
    }
}
function* watchGetAllLayers() { yield takeEvery(actions.GET_ALL_LAYERS_REQUEST, getAllLayers); }
function* watchGetDefaultLayer() { yield takeEvery(actions.GET_DEFAULT_LAYERS_REQUEST, getDefaultLayerAction); }
export default function* layerSaga() { yield all([fork(watchGetAllLayers), fork(watchGetDefaultLayer)]); }
